import moment from 'moment'

import compareArrays from '@/utility/functions/compareArrays'
import { AttachOrderEnum, methodEnum, AttachOrderMethod } from './enums'
import {
  mergeUpdateDictionary,
  nextLayer,
  findLastestConfig,
  promptSuccessively,
} from './attachOrderMulti'

export function attackOrderSinge(
  { selected, noticeOfNeed, oldOrders },
  value,
  reset = true,
) {
  return this.attachOrder({ selected, noticeOfNeed, oldOrders }, value, reset)
}

export function attachOrder(
  { selected, noticeOfNeed, oldOrders },
  value,
  reset = true,
) {
  const layersValue =
    value ??
    selected.reduce(
      (prev, curr) => prev + this.orders.find(x => x.id === curr).need,
      0,
    )

  const { newElements, removedElements } = compareArrays(oldOrders, selected)

  const adjustBookedByOne = (method, id) => {
    const index = this.ordersLayerBookedUpdate.findIndex(x => x.id === id)

    if (index === -1) {
      this.ordersLayerBookedUpdate.push({
        id,
        value: method === methodEnum.plus ? 1 : -1,
      })
      return
    }

    if (method === methodEnum.plus) {
      this.ordersLayerBookedUpdate[index].value += 1
      return
    }
    this.ordersLayerBookedUpdate[index].value -= 1
  }

  removedElements.forEach(id => adjustBookedByOne(methodEnum.minus, id))
  newElements.forEach(id => adjustBookedByOne(methodEnum.plus, id))

  this.inputContent(
    { value: layersValue, orders: selected },
    noticeOfNeed,
    reset,
  )
}

export async function attachOrderMulti(context) {
  if (context.fill) {
    this.attachOrderMultiFill(context)
    return
  }
  this.attachOrderMultiSplit(context)
}

export async function getUpdatedAttachOrder(
  { selected, noticeOfNeed, oldOrders },
  mode,
) {
  // config in layer
  const { config } = this.responsibleLayers[noticeOfNeed.layer]

  const calendarHolder = {
    [moment(noticeOfNeed.date, 'YYYY-MM-DD').week()]: JSON.parse(
      JSON.stringify(this.calendar),
    ),
  }

  /**
      @key date
      @value array of objects with key of layerIndex and value { selected, noticeOfNeed, oldOrders }
    */
  const toUpdateDictionary = {}

  const overwrittenArray = []

  // fill toUpdateDictionary
  for (
    let selectedIndex = 0;
    selectedIndex < selected.length;
    selectedIndex += 1
  ) {
    const selectedOrderId = selected[selectedIndex]

    // get full order object
    const order = this.orders.find(x => x.id === selectedOrderId)

    // count of layers needed to fullfil full order

    const orderLayerBookedUpdate = this.ordersLayerBookedUpdate.find(
      x => order.id === x.id,
    )

    const updatedValue = orderLayerBookedUpdate
      ? orderLayerBookedUpdate.value
      : 0

    const rest = (order.rest + updatedValue) % order.layersCount

    const restReverse =
      rest === 0 ? order.layersCount : Math.abs(rest - order.layersCount)

    // SET REST
    let layersLeft = restReverse - 1

    // fallback for preventing infinity loop
    let cap = 357

    // track current layer
    let trackCurrentLayer = {
      date: noticeOfNeed.date,
      layerIndex: noticeOfNeed.layerIndex,
    }

    let currentCalendar = moment(trackCurrentLayer.date, 'YYYY-MM-DD').week()

    while (layersLeft > 0 && cap > 0) {
      cap -= 1

      trackCurrentLayer = nextLayer(
        trackCurrentLayer.date,
        trackCurrentLayer.layerIndex,
        config.layers.length,
      )

      const trackCurrentLayerMoment = moment(
        trackCurrentLayer.date,
        'YYYY-MM-DD',
      )

      // new calendarWeek
      const nextCalendarWeek = trackCurrentLayerMoment.week()

      // check if is next week
      if (currentCalendar !== nextCalendarWeek) {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await this.$axios.get(
          `notice-of-need-calendar/calendar/${trackCurrentLayerMoment.get(
            'year',
          )}/${nextCalendarWeek}`,
        )

        calendarHolder[nextCalendarWeek] = this.calcCalender(data)
        currentCalendar = nextCalendarWeek
      }

      const calenderIndex = calendarHolder[currentCalendar].findIndex(
        x =>
          x.layer === noticeOfNeed.layer && x.index === noticeOfNeed.lineIndex,
      )
      const dateIndex = trackCurrentLayerMoment.isoWeekday() - 1

      const nextNoticeOfNeed =
        calendarHolder[currentCalendar][calenderIndex].noticeOfNeed[dateIndex][
          trackCurrentLayer.layerIndex
        ]

      if (nextNoticeOfNeed.orders.length !== 0) {
        if (mode === AttachOrderEnum.fill) {
          // eslint-disable-next-line no-continue
          continue
        }

        const alreadyIn = overwrittenArray.find(
          x =>
            x.date === nextNoticeOfNeed.date &&
            x.layer === nextNoticeOfNeed.layer &&
            x.layerIndex === nextNoticeOfNeed.layerIndex &&
            x.lineIndex === nextNoticeOfNeed.lineIndex,
        )

        if (!alreadyIn) {
          overwrittenArray.push(nextNoticeOfNeed)
        }
      }

      if (!toUpdateDictionary[trackCurrentLayer.date]) {
        toUpdateDictionary[trackCurrentLayer.date] = {}
      }
      if (
        typeof toUpdateDictionary[trackCurrentLayer.date][
          trackCurrentLayer.layerIndex
        ] !== 'object'
      ) {
        toUpdateDictionary[trackCurrentLayer.date][
          trackCurrentLayer.layerIndex
        ] = []
      }

      toUpdateDictionary[trackCurrentLayer.date][
        trackCurrentLayer.layerIndex
      ].push({
        selected: selected[selectedIndex],
        noticeOfNeed: { ...nextNoticeOfNeed, value: order.need },
        oldOrders,
      })

      layersLeft -= 1
    }
  }

  const updatedArray = mergeUpdateDictionary(toUpdateDictionary)

  updatedArray.unshift({ selected, noticeOfNeed, oldOrders })

  // check if entrance point is occupied
  const calenderIndex = this.calendar.findIndex(
    x => x.layer === noticeOfNeed.layer && x.index === noticeOfNeed.lineIndex,
  )
  const dateIndex = moment(noticeOfNeed.date, 'YYYY-MM-DD').isoWeekday() - 1

  const entrancePoint =
    this.calendar[calenderIndex].noticeOfNeed[dateIndex][
      noticeOfNeed.layerIndex
    ]
  if (entrancePoint.orders.length !== 0) {
    overwrittenArray.unshift(entrancePoint)
  }

  // overwrittenArray duplicate

  return { updatedArray, overwrittenArray }
}

async function getSetValue(context) {
  const askPromptSuccessively =
    context.selected.length !== 0 &&
    context.parallel === AttachOrderMethod.successively

  if (!askPromptSuccessively) return undefined

  const needs = context.selected.map(
    id => this.orders.find(x => x.id === id).need,
  )

  if (needs.every((val, i, arr) => val === arr[0])) {
    return needs[0]
  }

  const { value, choice } = await promptSuccessively.call(
    this,
    needs.reduce((a, b) => a + b, 0),
  )

  if (!choice) throw new Error('User closed popup without choosing any action')

  return value
}

export async function attachOrderMultiFill(context) {
  try {
    const { updatedArray } = await this.getUpdatedAttachOrder(
      context,
      AttachOrderEnum.fill,
    )

    const setValue = await getSetValue.call(this, context)

    await Promise.all(
      updatedArray.map(update => this.attachOrder(update, setValue, false)),
    )

    this.resetCalender()
  } catch (error) {
    // return regardless error
  }
}

export async function attachOrderMultiSplit(context) {
  try {
    const { config } = this.responsibleLayers[context.noticeOfNeed.layer]
    const { updatedArray, overwrittenArray } = await this.getUpdatedAttachOrder(
      context,
      AttachOrderEnum.split,
    )

    // find start of shifting
    const lastestConfig = findLastestConfig(updatedArray)

    let currentDate = lastestConfig.date
    let currentLayerIndex = lastestConfig.layerIndex
    let currentCalendar = moment(lastestConfig.date, 'YYYY-MM-DD').week()

    const calendarHolder = {
      [currentCalendar]: JSON.parse(JSON.stringify(this.calendar)),
    }

    const setValue = await getSetValue.call(this, context)

    let cap = 357
    while (overwrittenArray.length !== 0 && cap !== 0) {
      cap -= 1

      const { date, layerIndex } = nextLayer(
        currentDate,
        currentLayerIndex,
        config.layers.length,
      )
      currentDate = date
      currentLayerIndex = layerIndex

      const nextCalendarWeek = moment(currentDate, 'YYYY-MM-DD').week()

      // check if is next week
      if (currentCalendar !== nextCalendarWeek) {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await this.$axios.get(
          `notice-of-need-calendar/calendar/${moment(currentDate).get(
            'year',
          )}/${nextCalendarWeek}`,
        )

        calendarHolder[nextCalendarWeek] = this.calcCalender(data)
        currentCalendar = nextCalendarWeek
      }

      const calenderIndex = calendarHolder[currentCalendar].findIndex(
        x => x.layer === context.noticeOfNeed.layer,
      )
      const dateIndex = moment(currentDate, 'YYYY-MM-DD').isoWeekday() - 1

      const currentNoticeOfNeed = JSON.parse(
        JSON.stringify(
          calendarHolder[currentCalendar][calenderIndex].noticeOfNeed[
            dateIndex
          ][currentLayerIndex],
        ),
      )

      const overwrite = { ...overwrittenArray[0], date, layerIndex }

      if (!currentNoticeOfNeed.id) {
        delete overwrite.id
      }

      if (currentNoticeOfNeed.orders.length !== 0) {
        overwrittenArray.push(currentNoticeOfNeed)
      }

      this.attachOrder(
        {
          selected: overwrite.orders,
          noticeOfNeed: overwrite,
          oldOrders: currentNoticeOfNeed.orders,
        },
        overwrite.value,
        true,
      )
      overwrittenArray.shift()
    }

    updatedArray.forEach(update => {
      this.attachOrder(update, setValue, true)
    })

    this.resetCalender()
  } catch {
    // return regardless error
  }
}
