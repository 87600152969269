export default (arr1, arr2) => {
  const duplicates = []
  const removedElements = []
  const newArr = [...arr2]

  arr1.forEach(element => {
    if (newArr.includes(element)) {
      duplicates.push(element)
      newArr.splice(newArr.indexOf(element), 1)
    } else {
      removedElements.push(element)
    }
  })

  return { duplicates, newElements: newArr, removedElements }
}
